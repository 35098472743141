import { useCallback, useRef } from "react";

export interface IInputProps {
	id: string;
	label: string;
	type: string;
	value: string;

	copyToClipboard?: boolean;
	invalid?: boolean;
	pattern?: string;
	placeholder?: string;
	readOnly?: boolean;
	spellCheck?: boolean;

	onChange?: (value: string) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function Input({ id, label, type, value, copyToClipboard, invalid, pattern, placeholder, readOnly, spellCheck, onChange, onKeyDown }: IInputProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const onChangeCallback = useCallback(() => onChange && inputRef.current && onChange(inputRef.current.value), [inputRef, onChange]);
	const onKeyDownCallback = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && inputRef.current && onKeyDown(e), [inputRef, onKeyDown]);
	const isInvalid = invalid ?? (pattern != null && value.search(pattern) === -1);

	return (
		<div className="mb-3">
			<label className="form-label" htmlFor={id}>{label}</label>
			<div className="input-group">
				<input type={type}
					className={"form-control" + (isInvalid ? " is-invalid" : "") }
					id={id}
					ref={inputRef}
					placeholder={placeholder}
					readOnly={readOnly}
					spellCheck={spellCheck ?? false}
					value={value}
					onChange={onChangeCallback}
					onKeyDown={onKeyDownCallback}
				/>
				{copyToClipboard === true && (
					<button className="btn btn-tinted btn-tinted-primary" onClick={() => { void navigator.clipboard.writeText(value); }}>
						<span className="bi bi-clipboard me-2"></span> Copy
					</button>
				)}
			</div>
		</div>
	);
}
