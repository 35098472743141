import { useCallback, useRef } from "react";

export interface IUrlInputProps {
	id: string;
	label: string;
	value: string;

	invalid?: boolean;
	readOnly?: boolean;
	pattern?: string;
	placeholder?: string;

	onChange?: (value: string) => void;
}

export function UrlInput({ id, label, value, invalid, readOnly, pattern, placeholder, onChange }: IUrlInputProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const onChangeCallback = useCallback(() => onChange && inputRef.current && onChange(inputRef.current.value), [inputRef, onChange]);
	const isInvalid = invalid ?? (pattern != null && value.search(pattern) === -1);

	return (
		<div className="mb-3">
			<label className="form-label" htmlFor={id}>{label}</label>
			<div className="input-group">
				<input type="text"
					className={"form-control" + (isInvalid ? " is-invalid" : "")}
					id={id}
					ref={inputRef}
					readOnly={readOnly}
					value={value}
					placeholder={placeholder}
					onChange={onChangeCallback}
				/>
				<a href={value} className="btn btn-tinted btn-tinted-primary" target="_blank" rel="noopener noreferrer">
					<span className="bi bi-box-arrow-up-right me-2"></span> Open
				</a>
			</div>
		</div>
	);
}
